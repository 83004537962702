<template>
  <div>
    <c-table
      ref="table"
      title="법령 목록"
      tableId="lawpop"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="code"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'law-gubun-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '법령명',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$comm.getComboItems('LAW_ID').then(_result => {
        this.grid.data = this.$_.reject(_result, { useFlag: 'N' });
      });
    },
    /* eslint-disable no-unused-vars */
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '법령을 선택하세요.', // 법령을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
